const streakShareChromeExtensionUrl = 'https://chromewebstore.google.com/detail/streak-email-sharing-for/gpklpmejnkejffkogpfpgocfjfedmpbi/reviews'
const streakEmailTrackingChromeExtensionUrl = 'https://chromewebstore.google.com/detail/streak-email-tracking-for/jcgpgjhaendighananonflfmjjefjjlp/reviews'
const streakMailMergeChromeExtensionUrl = 'https://chromewebstore.google.com/detail/streak-mail-merge-for-gma/hhnhckmflikggaddmmcpgoehmnpnlglk/reviews'
const streakMainChromeExtensionUrl = 'https://chromewebstore.google.com/detail/streak-crm-for-gmail/pnnfemgpilpdaojpnkjdgfgbnnjojfik/reviews'


function browserDetection() {
  if ( /Android/i.test(navigator.userAgent) ) {
    $('.cta').attr('href', 'https://play.google.com/store/apps/details?id=com.mobile.streak');
    return 'Android';
  // some code..
  } else if (/webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    $('.cta').attr('href', 'https://itunes.apple.com/ca/app/streak-crm-for-gmail/id674067680?mt=8');
    return 'Mobile';
    // some code..
  } else if (/BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    $('.cta').attr('href', 'https://itunes.apple.com/ca/app/streak-crm-for-gmail/id674067680?mt=8');
    return 'Mobile';
  } else {
    if (navigator.userAgent.search("MSIE") > 0) {
      return 'IE';
    }
    //Legacy Edge
    else if (navigator.userAgent.search("Edge") > 0) {
      return 'Edge';
    }
    //Check if new Chromium-based Edge where we
    //have our extension in the store
    //Note: this is 'Edg' instead of 'Edge' -- this is intentional
    else if (navigator.userAgent.search("Edg/") > 0) {
      return 'Edg';
    }
    //Check if browser is Chrome
    else if (navigator.userAgent.search("Chrome") > 0 && navigator.userAgent.search("Edg") < 0) {
      return 'Chrome';
    }
    //Check if browser is Firefox
    else if (navigator.userAgent.search("Firefox") > 0) {
      return 'Firefox';
    }
    //Check if browser is Safari
    else if (navigator.userAgent.search("Safari") > 0  && navigator.userAgent.search("Chrome") < 0) {
      return 'Safari';
    }
    //Check if browser is Opera
    else if (navigator.userAgent.search("Opera") > 0) {
      return 'Opera';
    }
  }
}

function cleanUpInstallFlow() {
  $('.popup-wrapper').css({display: 'none'});
  $('.opening-gmail').css({display: 'none'});

  $('.master').css({display: 'none'});
  $('.master .popup-content-body').removeClass('show');
  $('.master .gmail-3-second-timer').removeClass('gmail-start');

  clearTimeout(gmailRedirectTimeout);

  // cws somehow blocks manually closing the window Ãƒâ€šÃ‚Â¯\_(ÃƒÂ£Ã†â€™Ã¢â‚¬Å¾)_/Ãƒâ€šÃ‚Â¯
  // but somehow pop ups to the Safari Extensions closes
  cwsPopup && cwsPopup.close();
}

function ctaLocation(e) {
  var el = e.target;
  var locations = [
    'home-hero-section',
    'nav-menu',
    'pre-footer',
    'pricing-card-item',
    'features-section'
  ];

  while (el.parentElement) {
    for (var i = 0; i < locations.length; i++) {
      if (el.parentElement.classList.contains(locations[i])) {
        return locations[i];
      }
    }

    el = el.parentElement;
  }

  return 'unknown';
}

function getURLParam(name) {
  return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(window.location.search) || [null, ''])[1].replace(/\+/g, '%20')) || null;
}

function handleCareerShow($this) {
  var $drawer = $this.parent().find('.career-drawer');
  var $caret = $this.find('.caret');

if ($drawer.is(':visible')) {
    $caret.css({transform: 'rotate(-90deg)'});
  } else {
    $caret.css({transform: 'rotate(0deg)'});
  }

  $drawer.slideToggle(150);
}

function streakIsInstalled(isFirst) {
  var $cta = $('.cta');

  $('.cta:not(.nav-cta)').text('Installed! Go to Gmail \u27F6');
  $('.nav-cta').text('Go to Gmail âŸ¶');
  $('.referral-cta').text('Go to Gmail \u27F6');

  $cta
    .attr('href', 'https://mail.google.com')
    .addClass('installed');

  if (isFirst) {
    trackEvent('visitWithExtensionInstalled', {
      label: browserDetection()
    });
    pdst('product', {
      value: 0.01,
      currency: 'USD',
      product_id: 'installed',
      product_name: 'Extension installed'
    });
  }
}

function streakIsNotInstalled(isFirst) {
  if (isFirst) {
    trackEvent('visitWithExtensionNotInstalled', {
      label: browserDetection()
    });
  }
}

function isExtensionInstalled(isFirst) {
  var browser = browserDetection();

  if (browser == 'Edg') {
    var INSTALL_CHECK = 'chrome-extension://gjfnhcobilifnmokegginjeenmlmlccn/blank.png';
    // the Edg(e) extension makes an image "web accessible", so we have the page try to load that image.
    // if it loads successfully then the Streak extension is installed. If the image does not load successfully, then the image is not installed
    // Note: this is basically *exactly* the same as the Chrome check below but with a CRX ID.
    var img = new Image();
    img.onload = function() {
      clearInterval(installTimerId);
      streakIsInstalled(isFirst);
    };

    img.onerror = function(){
      streakIsNotInstalled(isFirst);
      $('.cta').addClass('edg');
    };

    try {
      img.src = INSTALL_CHECK;
    }
    catch (error) {

    }
  }
  if (browser === 'Chrome') {
    const INSTALL_CHECK = (() => {
      switch (window.location.pathname) {
        case '/email-tracking-from-gmail':
          return 'chrome-extension://jcgpgjhaendighananonflfmjjefjjlp/blank.png' // streak email tracking extension
        case '/mail-merge-from-gmail':
        case '/mail-merge-gmail':
          return 'chrome-extension://hhnhckmflikggaddmmcpgoehmnpnlglk/blank.png';
        case '/streak-share-email':
          return 'chrome-extension://gpklpmejnkejffkogpfpgocfjfedmpbi/blank.png';
        default:
          return 'chrome-extension://pnnfemgpilpdaojpnkjdgfgbnnjojfik/blank.png'; // streak CRM extension
      }
    })()

    // the Chrome extension makes an image "web accessible", so we have the page try to load that image.
    // if it loads successfully then the Streak extension is installed. If the image does not load successfully, then the image is not installed
    var img = new Image();
    img.onload = function() {
      clearInterval(installTimerId);
      streakIsInstalled(isFirst);
    };

    img.onerror = function(){
      streakIsNotInstalled(isFirst);
      $('.cta').addClass('chrome');
    };

    try {
      img.src = INSTALL_CHECK;
    }
    catch (error) {

    }
  } else if (browser === 'Safari') {
    // for Safari, we have our Safari extension add an element to the page with id "streakExists" when going to a
    // streak page. So we have our script look for that element. If we can find it then we're good
    if( document.getElementById('streakExists')){
      clearInterval(installTimerId);
      streakIsInstalled(isFirst);
    } else {
      streakIsNotInstalled(isFirst);
    }
  } else if (browser === 'Mobile') {
    if (isFirst) {
      trackEvent('visitMobile', {
        label: browser
      });
    }
  } else {
    streakIsNotInstalled(isFirst);
  }
}

function openInstallPopup(url, popupHeight, popupWidth, heightBuffer) {
  var innerHeightMid = window.innerHeight / 2;
  var innerWidthMid = window.innerWidth / 2;
  var screenX = window.screenX;
  var screenY = window.screenY;

  var offsetX = popupWidth / 2;
  var offsetY = (popupHeight / 2) + heightBuffer;

  var left = screenX + innerWidthMid - offsetX;
  var top = screenY + innerHeightMid - offsetY;

  var windowFeatures = 'height=' + popupHeight + ', left=' + left + ', menubar=no, top=' + top + ', width=' + popupWidth;

  cwsPopup = window.open(
    url,
    '',
    windowFeatures
  );
}

function openUnsupportedModal() {
  trackEvent('browserNotSupportedModal', {
    label: browserDetection()
  });

  // $('.not-supported').css({display: 'flex'})
  // $('.modal-screen.unsupported').fadeIn(200);

  $('.master').css({ display: 'flex' });
  $('.master .not-supported').parent().css('display', 'flex');
  $('.master .not-supported').addClass('show');
}

function intervalCheckInstalled(max) {
  var current = 0;
  var delay = 500;

  installTimerId = setInterval(function(){
    current += delay;
    if (current > max) {
      clearInterval(installTimerId);
      streakIsNotInstalled();
    }
    isExtensionInstalled();
  }, delay);

  var isFirst = true;
  isExtensionInstalled(isFirst);
}

function setCookies() {
  var source = getURLParam('utm_source');
  if (source) {
    Cookies.set('utm_source', source, {
      expires: 365,
      path: '/',
      domain: window.location.hostname,
      secure: true
    });
  }

  var medium = getURLParam('utm_medium');
  if (medium) {
    Cookies.set('utm_medium', medium, {
      expires: 365,
      path: '/',
      domain: window.location.hostname,
      secure: true
    });
  }

  var term = getURLParam('utm_term');
  if (term) {
    Cookies.set('utm_term', term, {
      expires: 365,
      path: '/',
      domain: window.location.hostname,
      secure: true
    });
  }

  var device = getURLParam('utm_device');
  if (device) {
    Cookies.set('utm_device', device, {
      expires: 365,
      path: '/',
      domain: window.location.hostname,
      secure: true
    });
  }

  var campaign = getURLParam('utm_campaign');
  if (campaign) {
    Cookies.set('utm_campaign', campaign, {
      expires: 365,
      path: '/',
      domain: window.location.hostname,
      secure: true
    });
  }

  var adgroup = getURLParam('utm_adgroup');
  if (adgroup) {
    Cookies.set('utm_adgroup', adgroup, {
      expires: 365,
      path: '/',
      domain: window.location.hostname,
      secure: true
    });
  }

  var content = getURLParam('utm_content');
  if (content !== null) {
    Cookies.set('utm_content', content, {
      expires: 365,
      path: '/',
      domain: window.location.hostname,
      secure: true
    });
  }
}

/* set cookie for some paths
  ** Streak installations from the following paths need custom onboarding flow:
  - /mail-merge-from-gmail
  - /email-tracking-from-gmail
  - /streak-share-email

  ** Referral link path:
  - /r/{referralCode}
*/
function setCookieForPath() {
  var pathName = window.location.pathname;

  var referralCode = pathName.includes('/r/') && pathName.split('/').slice(-1)[0];
  if (referralCode) {
    Cookies.set('referralCode', referralCode, {
      expires: 365,
      path: '/',
      domain: window.location.hostname,
      secure: true
    });
  }

  // todo: double check if these are used
  var customOnboardingPaths = ['/mail-merge-from-gmail', '/email-tracking-from-gmail', '/streak-share-email']
  if (customOnboardingPaths.includes(pathName)) {
    Cookies.set('customOnboarding', pathName, {
      expires: 365,
      path: '/',
      domain: window.location.hostname,
      secure: true
    });
  }
}

function startRedirect() {
  $('.popup-container-absolute').css("display", "flex");

  $('.master .popup-content-body').removeClass('show');
  $('.master .waiting-install').addClass('show');

  setTimeout(() => {
    $('.master .waiting-install').removeClass('show');
    $('.master .success').addClass('show');
  }, 1000);

  setTimeout(() => {
    $('.master .success').removeClass('show');
    $('.master .opening-gmail').addClass('show');
  }, 2000);

  setTimeout(() => {
    $('.master .gmail-3-second-timer').addClass('gmail-start');
  }, 2050);

  // cws somehow blocks manually closing the window
  // but somehow pop ups to the Safari Extensions closes
  cwsPopup && cwsPopup.close();

  if (gmailRedirectTimeout) {
    clearTimeout(gmailRedirectTimeout);
  }

  gmailRedirectTimeout = setTimeout(function() {
    window.location.href = 'https://mail.google.com';
  }, 4750);
}

function trackSuccessfulInstall() {
  isInstalled = true;
  trackEvent('installationSuccessful', {
    ctaLocation: lastCtaLocation,
    label: browserDetection()
  });
  pdst('product', {
    value: 0.01,
    currency: 'USD',
    product_id: 'installed',
    product_name: 'Extension installed'
  });
}

function getStreakUsers() {
  var re = /(?:^|;)\s*([^\s=;]+)uid=/g;
  var m;
  var cookie = document.cookie;
  var users = [];
  while ((m = re.exec(cookie))) {
    var email = decodeURIComponent(m[1].replace(/\+/g, ' '));
    users.push(email);
  }
  return users;
}

function trackEvent(eventAction, extraEventProps) {
  // Create a send the event to Google Analytics
  var gaEvent = {
    hitType: 'event',
    eventCategory: 'Website',
    eventAction: eventAction
  };
  if (extraEventProps && extraEventProps.label) {
    gaEvent.eventLabel = extraEventProps.label;
  }
  ga('send', gaEvent);
  sendEventToBigQuery(eventAction, extraEventProps);
}

// The following functions (through "sendEventToBigQuery") are adapted from the functions of the
// same names found in GmailSDK/src/platform-implementation-js/lib/logger.js.
var _currentAccessToken = null;
var _sessionId = Date.now()+'-'+Math.random();
var _gaHttpReferrerPromise = new Promise(resolve => {
  ga((tracker) => {
    resolve(tracker.get('referrer'));
  });
});
var _gaClientIdPromise = new Promise(resolve => {
  ga((tracker) => {
    resolve(tracker.get('clientId'));
  });
});

function isTimestampExpired(n) {
  // Let's refresh the token 10 minutes early
  return (Date.now() + (10*60*1000)) > n;
}

function retrieveNewEventsAccessToken() {
  return fetch('https://www.inboxsdk.com/api/v2/events/oauth')
    .then((response) => response.json())
    .then((accessToken) => {
      if (isTimestampExpired(accessToken.expirationDate)) {
        console.warn('Got an apparently already expired token. Assuming our clock is busted...');
        // Let's assume the token expires in 30 minutes. The server refreshes the
        // token 30 minutes before it expires so it's probably a safe bet.
        accessToken.expirationDate = Date.now()+30*60*1000;
      }
      return accessToken;
    });
}

function getEventsAccessToken() {
  return new Promise(resolve => {
      const t = _currentAccessToken;
      if (t && !isTimestampExpired(t.expirationDate)) {
        resolve(t);
      } else {
        resolve(retrieveNewEventsAccessToken());
      }
    })
    .then(accessToken => {
      _currentAccessToken = accessToken;
      return _currentAccessToken;
    });
}

function sendEventToBigQuery(eventName, extraEventProps) {
  Promise.all([
      getEventsAccessToken(),
      _gaClientIdPromise,
      _gaHttpReferrerPromise
    ])
    .then((results) => {
      var accessToken = results[0];
      var gaClientId = results[1];
      var gaHttpReferrer = results[2];

      var paramsIterator = new URLSearchParams(window.location.search).entries();
      var queryParams = {};
      for (var pair of paramsIterator) {
         queryParams[pair[0]] = pair[1];
      }

      var apiKey = 'AIzaSyAwlvUR2x3OnCeas8hW8NDzVMswL5hZGg8';
      var oauthToken = accessToken.oauthToken;
      var event = {
        type: 'app',
        event: eventName,
        timestamp: Date.now()*1000,
        origin: document.location.origin,
        appIds: [{appId: 'streakWebsite', causedBy: true}],
        sessionId: _sessionId,
        properties: Object.assign({}, extraEventProps, {
          gaClientId, queryParams,
          gaHttpReferrer,
          pageUrl: document.location.href,
          users: getStreakUsers()
        })
      };

      fetch(
        'https://pubsub.googleapis.com/v1/projects/mailfoogae/topics/events:publish?key=' + encodeURIComponent(apiKey),
        {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + oauthToken,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            messages: [
              {
                data: window.btoa(JSON.stringify({
                  data: [event],
                  timestamp: Date.now()*1000
                }))
              }
            ]
          })
        }
      );
    });
}


var browser = browserDetection();
var cwsPopup;              // ref to cws popup
var installTimerId;
var gmailRedirectTimeout;  // timeout redirecting to gmail after install
var lastCtaLocation;       // e.g. nav, footer

// our current homepage checks for 2 seconds
intervalCheckInstalled(2000);

$(document).ready(function() {
  // clean up on ready in case there's a hanging modal.
  // the user will see the redirect modal if:
  // 1. install 2. redirect to gmail 3. clicks back to go to streak.com
  // then the redirect modal will still be active
  cleanUpInstallFlow();
  if (browser === 'Edg') {
    // Attempt to update the install prompt's text. The element will not be present if Streak is
    // already installed.
    let installPrompt = $('a.cta').toArray();
    if (installPrompt.length > 0) {
      installPrompt.forEach((el) => el.innerText = 'add to Edge')
    }
  } else if (browser === 'Safari') {
    // Attempt to update the install prompt's text. The element will not be present if Streak is
    // already installed.
    let installPrompt = $('a.cta').toArray();
    if (installPrompt.length > 0) {
      installPrompt.forEach((el) => el.innerText = 'add to Safari');
    }
  } else {
    if (browser != 'Chrome') {
      // Attempt to update the install prompt's text. This will update anything that is not
      // Safari, Chrome, or Edge.
      let installPrompt = $('a.cta').toArray();
      if (installPrompt.length > 0) {
        installPrompt.forEach((el) => el.innerText = 'install now');
      }
    }
  }

  window.addEventListener('onStreakInstall', () => {
    streakIsInstalled();
    trackSuccessfulInstall();
    startRedirect();
  });

  // highlight active tab
  $('.nav-link.w--current > .nav-link-hover-line').addClass('underline-nav-link');


  $('.popup-container-absolute').css("display", "none");

  if (window.location.search) {
    setCookies();
  }

  if (window.location.hash) {
    var hash = window.location.hash;
    var $item = $(hash).find('.career-item-header');

    if ($item) {
      handleCareerShow($item);
    }
  }

  if (window.location.pathname) {
    setCookieForPath();
  }
});

$('.career-item-header').on('click', function(e) {
  var $this = $(this);
  handleCareerShow($this);
});

$('.modal-screen').css({display: 'none', 'z-index': 1000});

$('.popup-modal-background').on('click', function() {
  if (browserDetection() === 'Chrome') {
    cwsPopup.focus();
  }
});

/** Listener for user closing site modal. */
$('.popup-x-wrapper').on('click', function() {
  cleanUpInstallFlow();
});

/** Listener for user clicking cta (install) actions. */
$('.cta').on('click', function(e) {
  lastCtaLocation = ctaLocation(e);

  var $this = $(this);

  var popupHeight;
  var popupWidth;
  var url;

  trackEvent('downloadButtonPressed', {
    ctaLocation: lastCtaLocation,
    label: browser
  });
  pdst('lead', {
    value: 0.01,
    currency: 'USD',
    type: 'downloadButton'
  });

  /* todo test block above, remove block below */
  if (browser === 'Edg') {
    if (!$this.hasClass('installed')) {
      e.preventDefault();

      popupHeight = Math.min(window.innerHeight, 600);
      popupWidth = Math.min(window.innerWidth, 1200);
      url = 'https://microsoftedge.microsoft.com/addons/detail/gjfnhcobilifnmokegginjeenmlmlccn?hl=en-US';

      openInstallPopup(url, popupHeight, popupWidth, 0)
      $('.master').css({display: 'flex'});
      $('.master .add-to-edge').parent().css("display", "flex");
      $('.master .add-to-edge').addClass('show');
    }
  } else if (browser === 'Chrome') {
    if (!$this.hasClass('installed')) {
      e.preventDefault();

      popupHeight = Math.min(window.innerHeight, 600);
      popupWidth = Math.min(window.innerWidth, 1200);
      url = streakMainChromeExtensionUrl;

      switch (window.location.pathname) {
        // open email trakcing extension url if user clicks install from email tracking landing page: https://www.streak.com/email-tracking-from-gmail
        case '/email-tracking-from-gmail': {
          url = streakEmailTrackingChromeExtensionUrl
          break;
        }
        case '/mail-merge-from-gmail':
        case '/mail-merge-gmail': {
          url = streakMailMergeChromeExtensionUrl
          break;
        }
        case '/streak-share-email': {
          url = streakShareChromeExtensionUrl
          break;
        }
      }

      openInstallPopup(url, popupHeight, popupWidth, 0)
      $('.master').css({ display: 'flex' });
      $('.master .add-to-chrome').parent().css("display", "flex");
      $('.master .add-to-chrome').addClass('show');
    }
  } else if (browser === 'Safari') {
    /** new safari install flow */
    if (!$this.hasClass('installed')) {
      e.preventDefault();

      //Modify the URL to open to Apple's Safari Extension store
      window.open('https://apps.apple.com/us/app/streak-for-safari/id1482708290?mt=12');
    }
  } else if (browser === 'Mobile') {
    window.location.replace('https://itunes.apple.com/ca/app/streak-crm-for-gmail/id674067680?mt=8');
  } else if (browser === 'Android') {
    window.location.replace('https://play.google.com/store/apps/details?id=com.mobile.streak');
  } else {
    e.preventDefault();
    openUnsupportedModal();
  }
});

$('.safari').on('click', function(e) {
  $(this).fadeOut(200);
});

$('.installed').on('click', function(e) {
  trackEvent('clickedToGmail', {
    clickLocation: ctaLocation(e),
    label: browserDetection()
  });
});

$('.unsupported-form').on('submit', function(e) {
  e.preventDefault();

  var $this = $(this);
  var email = $this.find('input[type="email"]').val();

  $.post('https://www.streak.com/ajaxcalls/emailSignup',  { userAgent: navigator.userAgent, email})

  trackEvent('subscribeNotifyUnsupportedBrowser', {
    label: browserDetection()
  })
});

$('.close-modal').on('click', function(e) {
  $('.modal-screen').fadeOut(200);
});
